<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <h2 class="fs-2 fw-bold my-2">
            <template v-if="pageID">
                {{ sprintf($t('pages.page.save.editPage'), [page.translate.title ?? '']) }}
                <a class="btn btn-icon btn-light btn-bg-light btn-active-color-primary btn-sm me-1 ms-1" :href="$root.appUrl + '/' + page.translate.slug" target="_blank">
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="/media/icons/duotune/coding/cod007.svg"/>
                    </span>
                </a>
            </template>
            <template v-else>{{ $t('pages.page.save.newPage') }}</template>
        </h2>

        <router-link v-if="!$root.filterWithUrl" to="/page" class="btn btn-primary align-self-center ms-4 back-page-btn">
            <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.page.title") }}
        </router-link>
    </div>
    <el-form :model="form" ref="pageForm">
        <div class="row g-6 mb-6 g-xl-9 mb-xl-9">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.page.cols.title') }}</label>
                            <el-form-item prop="translate.title" :rules="$validation.getMessage(['required'])">
                                <el-input v-model="form.translate.title" type="text"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.page.save.cols.slug') }}</label>
                            <el-form-item prop="translate.meta.slug">
                                <el-input v-model="form.translate.slug" type="text"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.page.save.cols.content') }}</label>
                            <el-form-item prop="translate.content" :rules="$validation.getMessage(['required'])">
                                <CustomCkeditor :model="form.translate.content" @updateModel="form.translate.content = $event"></CustomCkeditor>
                            </el-form-item>
                        </div>
                    </div>
                </div>

                <div class="card mt-7">
                    <div class="card-header pt-4 pb-4">
                        <h3 class="card-title align-items-start flex-column">
                            {{ $t('pages.page.save.seoSettings') }}
                        </h3>
                    </div>
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.page.save.seoCols.title') }}</label>
                            <el-form-item prop="translate.meta.title">
                                <el-input v-model="form.translate.meta.title" type="text"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.page.save.seoCols.description') }}</label>
                            <el-form-item prop="translate.meta.description">
                                <el-input v-model="form.translate.meta.description" type="textarea" rows="3"/>
                            </el-form-item>
                        </div>
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.page.save.seoCols.keywords') }}</label>
                            <el-form-item prop="translate.meta.keyword">
                                <el-input v-model="form.translate.meta.keyword" type="textarea" rows="3"/>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="card">
                    <div class="card-body d-flex flex-column p-9">
                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.page.save.cols.poster') }}</label>
                            <el-form-item prop="image_id">
                                <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" :on-remove="handleChangeImage" list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
                                    <i class="bi bi-plus"/>
                                </el-upload>
                                <el-dialog v-model="image.dialogVisible" width="30%">
                                    <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
                                </el-dialog>
                            </el-form-item>
                        </div>

                        <div class="fw-row mb-1">
                            <label class="required fs-6 fw-bold mb-2">{{ $t('pages.page.save.cols.pageWidth') }}</label>
                            <el-form-item prop="data.options.page_width" :rules="$validation.getMessage(['required'])">
                                <el-select v-model="form.data.options.page_width" class="w-100" :placeholder="$t('common.chooseSelect')">
                                    <el-option v-for="(pageWidth, pageWidthIndex) in pageWidths" :key="pageWidthIndex" :value="pageWidth" :label="$t('pages.page.save.pageWidth.' + pageWidth.toLowerCase())"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                            <el-form-item prop="data.sort" :rules="$validation.getMessage(['required'])">
                                <el-input-number v-model="form.data.sort" :min="1"/>
                            </el-form-item>
                        </div>

                        <div class="fv-row mb-1">
                            <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                            <el-form-item prop="data.active">
                                <el-radio-group v-model="form.data.active">
                                    <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                    <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                        <div class="fv-row mt-5">
                            <el-form-item class="mb-0">
                                <button @click.prevent="onSubmit" :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary w-100" type="button">
                                    <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                                    <span v-if="form.loading" class="indicator-progress">
                                        {{ $t("messages.wait") }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
import CustomCkeditor from "@/components/custom-ckeditor"

export default {
    name: "_id",
    components: {
        CustomCkeditor
    },
    data() {
        return {
            form: {
                updateStatus: false,
                loading: false,
                translate: {
                    language_id: this.$root.defaultLanguage.id,
                    meta: {}
                },
                data: {
                    options: {
                        page_width: 'NORMAL'
                    },
                    sort: 1,
                    active: true
                }
            },
            page: {
                translate: {}
            },
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            },
            pageWidths: ['NORMAL', 'LARGE']
        }
    },
    computed: {
        pageID() {
            return this.$route.params.id;
        }
    },
    created() {
        if (this.pageID && !(this.pageID > 0)) {
            this.$router.push({
                path: "/page"
            });
        }
    },
    mounted() {
        if (this.pageID && this.pageID > 0) {
            this.loadPage();
        }
    },
    methods: {
        loadPage(pageID = this.pageID) {
            this.resetImageData();

            this.axios.get(this.endpoints['page'] + '/' + pageID).then((response) => {
                let data = response.data.data;

                this.page = data;
                this.page.translate = this.resolveDatum(data.translations, this.$root.defaultLanguage.id, 'language_id');

                let translateLinks = this.resolveDatum(data.links, this.form.translate.language_id, 'language_id');
                let translate = this.resolveDatum(data.translations, this.form.translate.language_id, 'language_id');

                this.form.translate = this.cloneData(
                    Object.assign(translate, {
                        meta: {
                            title: translateLinks.title,
                            description: translateLinks.description,
                            keyword: translateLinks.keyword
                        }
                    })
                );

                if(data.image){
                    this.image.fileList = [{ name: data.image.name, url: data.image.public_url }]
                }

                if (!data.options && Array.isArray(data.options)) {
                    data.options = {};
                }

                this.form.updateStatus = true;
                this.form.data = data;
            })
        },
        onSubmit() {
            this.$refs.pageForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        if (!(this.image.fileList.length > 0)) {
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints["file"], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.image.uploadList = [];
                    this.submitForm();
                }, () => {
                    this.form.loading = false;
                }, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data, () => {
                    this.form.loading = false;
                });
            });
        },
        submitForm() {
            let formData = this.prepareFormData();

            this.form.loading = true;

            if (this.form.data.id) {
                this.axios.put(this.endpoints['page'] + '/' + this.form.data.id, formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.loadPage();
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            } else {
                this.axios.post(this.endpoints['page'], formData).then(response => {
                    this.onResponse(response.data, () => {
                        this.$router.push({
                            path: "/page/save/" + response.data.data.id
                        });
                        this.loadPage(response.data.data.id);
                    }, () => {
                        this.form.loading = false;
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data, () => {
                        this.form.loading = false;
                    });
                });
            }
        },
        prepareFormData() {
            let formData = this.form.data;

            return {...this.form.translate, ...formData}
        },
        resetImageData() {
            this.image = {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;
        }
    }
}
</script>

<style>
.back-page-btn {
    height: 40px;
    line-height: 1.25;
}
</style>